import BaseForm from '@/shared/models/BaseForm';
class VenueCateringModel extends BaseForm {
    cateringItems;
    id;
    ownCatering;
    constructor() {
        super();
        this.id = '';
        this.ownCatering = true;
        this.cateringItems = [];
    }
    apiAttributes() {
        return {
            id: 'id',
            cateringItems: 'catering-items'
        };
    }
}
export default VenueCateringModel;
