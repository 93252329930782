import patch from '@/api/base/with-authorization/patch';
/**
 * Patch venue.
 *
 * @param {number} id Venue ID.
 * @param {object} options Options.
 *
 * @returns {Promise}
 */
export default function (id, options) {
    return patch(`venue/${id}/`, options);
}
